<template>
  <div class="otherIndex" id="financial">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'otherIndex'
}
</script>

<style scoped>
.otherIndex {
  width: 100%;
  height: 100%;
}
</style>
